import { bbVars } from './configuration'
import { bbGetBBScope, bbGetUrlBase, getButtons, getCardContents, getCardBrands, isIframe, fromRedirect, bbSetBBPhase, bbShowLoadingPage, bbDisableAllBtns } from './utils'
import { bbLoadInterface, bbLoadScript } from './interface'
import { bBGET, bBPOST, bBPUT, bBInterceptor } from './http'
import { bbDispatchNextEvent, bbDispatchPreviousEvent, bbAfterPayError, bbPaymentComplete, bbPaymentError, bbDispatchCancel, bbCancelRequest, bbCloseModal } from './events';
import { bbAuthenticatedPageLoaded, checkWindowIsClose } from './authentication';
import { bbSelectPlanCustomMode } from './payment-plans';

import '../sass/bankbridge.scss';

var init = (config = {}) => {
    bbVars.bbConfig = config;
    if (!bbVars.bbConfig['payment']['token']) {
        console.log('Must provide an authorization token');
    }
    bbVars.bbToken = bbVars.bbConfig['payment']['token'];

    isIframe();
    bbGetUrlBase();
    bbLoadScript();
    getButtons();
    getCardContents();
    getCardBrands();
    bbGetBBScope(bbVars.bbConfig['payment']['sessionId']);
    bbLoadInterface();
}

var initAuthenticated = (config = {}) => {
    bbVars.bbConfig = config;
    if (!bbVars.bbConfig['payment']['token']) {
        console.log('Must provide an authorization token');
    }
    bbVars.bbToken = bbVars.bbConfig['payment']['token'];

    isIframe();
    fromRedirect();
    bbGetUrlBase();
    bbLoadScript();
    getButtons();
    getCardContents();
    getCardBrands();
    bbGetBBScope(bbVars.bbConfig['payment']['sessionId']);
    bbLoadInterface();

    setTimeout(() => {
        checkInitPhase();
    }, 1000);
}

var checkInitPhase = () => {
    if (bbVars.bbCurrentPhase == bbVars.bbPhases.BB_INIT_PHASE) {
        bbSetBBPhase(bbVars.bbPhases.BB_AUTHENTICATE_PHASE);
        bbShowLoadingPage('Autenticando proceso de pago');
        bbDisableAllBtns();
        onAuthenticated(bbVars.bbAuthenticationComplete);
    } else {
        setTimeout(() => {
            checkInitPhase();
        }, 250);
    }
}

var next = (event) => {
    if (bbVars.bbCurrentPhase != bbVars.bbPhases.BB_INIT_PHASE) {
        throw new Error('next: El pago se encuentra cancelado, pagado o existe un problema con la solicitud');
        return;
    }
    if (bbVars.bbConfig['interface'] == 'custom') {
        throw new Error('Esta función no esta disponible en el modo custom');
    }
    bbDispatchNextEvent(event);
}

var previous = (event) => {
    if (bbVars.bbCurrentPhase == bbVars.bbPhases.BB_CANCELED_PHASE) {
        throw new Error('previous: El pago se encuentra cancelado, pagado o existe un problema con la solicitud');
        return;
    }
    if (bbVars.bbConfig['interface'] == 'custom') {
        throw new Error('Esta función no esta disponible en el modo custom');
    }
    bbDispatchPreviousEvent(event);
}

var cancel = () => {
    if (bbVars.bbCurrentPhase == bbVars.bbPhases.BB_CANCELED_PHASE) {
        throw new Error('El pago ya se encuentra cancelado');
        return;
    }
    bbDispatchCancel();
}

var close = () => {
    bbCloseModal();
}

var pay = () => {
    if (bbVars.bbCurrentPhase == bbVars.bbPhases.BB_CANCELED_PHASE || bbVars.bbCurrentPhase == bbVars.bbPhases.BB_PAYED_PHASE) {
        throw new Error('pay: El pago se encuentra cancelado, pagado o existe un problema con la solicitud');
        return;
    }
    bbDispatchNextEvent('pay');
}

var onAuthenticated = (message) => {
    if (bbVars.bbCurrentPhase == bbVars.bbPhases.BB_CANCELED_PHASE) {
        throw new Error('El pago se encuentra cancelado');
        return;
    }
    if (message == bbVars.bbAuthenticationComplete)
        bbAuthenticatedPageLoaded(message);
    if (message == bbVars.bbAuthenticationClose)
        setTimeout(() => checkWindowIsClose(message), 100);
}

var selectPlan = (planId, months) => {
    if (bbVars.bbCurrentPhase == bbVars.bbPhases.BB_CANCELED_PHASE) {
        throw new Error('El pago se encuentra cancelado');
        return;
    }
    bbSelectPlanCustomMode(planId, months);
}

var validations = (validationCallback) => {
    if (!validationCallback) {
        validationCallback = () => {};
    }
    bbVars.bbValidationCallback = validationCallback;
}

var paymentPlans = (paymentPlansCallback) => {
    if (!paymentPlansCallback) {
        paymentPlansCallback = () => {};
    }
    bbVars.bbPaymentPlansCallback = paymentPlansCallback;
}

var paymentResults = (resultCallback) => {
    if (!resultCallback) {
        resultCallback = () => {};
    }
    bbVars.bbPaymentResultsCallback = resultCallback;
}

var onPaymentError = () => bbPaymentError();
var onPaymentComplete = () => bbPaymentComplete();

var RequestNew = (data) => {
    if (!data) {
        data = {
            campusId: "340",
            reference: "REF0000123TEST",
            amount: 2000,
            operationTime: 20,
            application: 'VIRTUAL-TELLER'
        }
    }
    bbGetUrlBase();
    return bBPOST('test/request', data);
}


export { RequestNew, init, initAuthenticated, next, previous, cancel, close, onAuthenticated, selectPlan, validations, paymentPlans, paymentResults, onPaymentError, onPaymentComplete, pay }