import { bbVars } from './configuration'
import {
    bbEnablePayBtn,
    bbDisablePayBtn,
    bbEnableNextPlansBtn,
    bbDisableNextPlansBtn,
    bbEnablePreviousPlansBtn,
    bbDisablePrevoiusPlansBtn,
    bbShowPayBtn,
    bbHidePayBtn,
    bbShowNextPlansBtn,
    bbHideNextPlansBtn,
    bbShowPreviousPlansBtn,
    bbHidePreviousPlansBtn,
    bbEnableAllBtns,
    bbShowPaymentPlansPage,
} from './utils';

import { bbAfterPayError } from './events'
import { bBGET } from './http'

function bbBtnPlanEvent() {
    bbVars.formSessionUpdated = true;
    bbVars.formSessionUpdateCallBack = () => {
        bbGoToPlansSelector();
    };
    //bbEnableAllBtns();
    PaymentSession.updateSessionFromForm('card', 'card-type', bbVars.bbScope);
}

function bbResetPlans() {
    if (bbVars.bbConfig['interface'] != 'custom') {
        bbDisableNextPlansBtn();
        bbHideNextPlansBtn();
        bbDisablePrevoiusPlansBtn();
        bbHidePreviousPlansBtn();
        bbShowPayBtn();
        bbEnablePayBtn();
    }
    bbResetPlanSelected();
}

function bbResetPlanSelected() {
    bbVars.bbPlanSelected = {
        PlanId: '',
        NumberOfPayments: null,
        NumberOfDeferrals: null
    };
}

function bbGetPaymentPlans(selector, result) {
    bbResetPlanSelected();
    if (result['fundingMethod'] == 'CREDIT') {
        if (bbVars.bbConfig['interface'] != 'custom')
            bbDisablePayBtn();
        bBGET('api/Request/paymentPlans').subscribe({
            next: (data) => {
                bbGetAfterPaymetPlans(data);
            },
            error: (error) => {
                bbVars.bbPaymentPlans = [];
                bbDispatchPaymentPlans();
                bbAfterPayError({
                    id: 'BB_PAYMENT_PLANS_ERROR',
                    message: 'Error al obtener los planes de pago',
                    type: 'error',
                    error: {
                        trace: null
                    }
                }, true);
                bbEnablePayBtn();
            }
        });
    }
}

function bbGetAfterPaymetPlans(data) {
    bbVars.bbPaymentPlans = [];
    if (data['result'] == 'SUCCESS' && data['merchant'] == bbVars.merchant) {
        if (!!data['paymentTypes'] && !!data['paymentTypes']['card'] && !!data['paymentTypes']['card']['paymentPlans']) {
            if (data['paymentTypes']['card']['paymentPlans'].length >= 1) {
                bbVars.bbPaymentPlansData = data;
                bbVars.bbPaymentPlans = data['paymentTypes']['card']['paymentPlans'];
                if (bbVars.bbConfig['interface'] != 'custom') {
                    bbShowNextPlansBtn();
                    bbEnableNextPlansBtn();
                    bbHidePayBtn();
                }
                bbDispatchPaymentPlans();
                return;
            }
        }
    }
    if (bbVars.bbConfig['interface'] != 'custom')
        bbEnablePayBtn();
    bbDispatchPaymentPlans();
}

function bbDispatchPaymentPlans() {
    var plans = JSON.parse(JSON.stringify(bbVars.bbPaymentPlans));
    plans.unshift({
        endDate: '',
        minimumOrderAmounts: [],
        numberOfPayments: [],
        numberOfDeferrals: null,
        payerInterest: "false",
        planId: 'NONEPLAN',
        planName: 'Ningún plan de pago',
        planTemplate: '',
        startDate: ''
    });
    bbVars.bbPaymentResultsCallback({
        id: 'BB_PAYMENT_PLANS_SUCCESS',
        message: 'Planes de pago cargados',
        type: 'success',
        data: plans
    });
    if (bbVars.bbPaymentPlansCallback) {
        bbVars.bbPaymentPlansCallback(plans);
    }

}

function bbGoToPlansSelector() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;

    var tableElement = document.querySelector('.bb-payment-plans-tables');

    var htmlElement = '';

    var htmlNoneString = '<table class="bb-table bb-table-bordered"><tr><td class="plan-checkbox"><input type="radio" id="NONEPLAN_radio" name="inputplan" value="NONEPLAN" checked></td><td>Ning&uacute;n plan de pago</td></tr></table>';
    var htmlString = '<table class="bb-table bb-table-bordered bb-payment-plan-table"><tr><td class="plan-checkbox"><input type="radio" id="{{plan_id}}_radio" name="inputplan" value="{{plan_value}}"></td><td>Pagar con: {{plan_description}}</td></tr><tr><td colspan="2"><div class="bb-form-inline bb-plan-selections">{{plan_option}} meses <div class="bb-payer-interest"> sin inter&eacute;s</div></div></td></tr></table>';

    var htmlTableString = '<table class="bb-table bb-table-bordered bb-payment-plan-table"><tr><td class="plan-checkbox"><input type="radio" id="{{plan_id}}_radio" name="inputplan" value="{{plan_value}}"></td><td>Pagar con: {{plan_description}}</td></tr>{{plan_select}}</table>';
    var htmlTrString = '<tr><td colspan="2"><div class="bb-form-inline bb-plan-selections"><div class="bb-form-group"><label class="bb-mr-5">Selecciona</label><select id="{{plan_id}}_select" name="{{plan_id}}_select" class="bb-form-control bb-plan-select-control">{{select_values}}</select><div class="bb-payer-interest"> sin inter&eacute;s</div></div></div></td></tr>';

    htmlElement = htmlElement + htmlNoneString;

    bbVars.bbPaymentPlansData['paymentTypes']['card']['paymentPlans'].forEach((plan, planIndex) => {
        if (!!plan['numberOfPayments'] && plan['numberOfPayments'].length > 1) {
            var selectOptions = '';
            var option = '<option value="{{option_value}}">{{option_description}} meses</option>';
            plan['numberOfPayments'].forEach((numberof, numberIndex) => {
                var optionData = option.slice();
                optionData = optionData.replace('{{option_value}}', numberof).replace('{{option_description}}', numberof);
                selectOptions = selectOptions + optionData;
            });

            var selectElement = htmlTrString.slice();
            selectElement = selectElement.replace('{{plan_id}}', plan['planId']).replace('{{plan_id}}', plan['planId']).replace('{{select_values}}', selectOptions);

            var element = htmlTableString.slice();
            element = element.replace('{{plan_id}}', plan['planId']).replace('{{plan_value}}', plan['planId']).replace('{{plan_description}}', plan['planName']).replace('{{plan_select}}', selectElement);
            htmlElement = htmlElement + element;
        } else {
            var element = htmlString.slice();
            element = element.replace('{{plan_id}}', plan['planId']).replace('{{plan_value}}', plan['planId']).replace('{{plan_description}}', plan['planName']).replace('{{plan_option}}', plan['numberOfPayments'][0]);
            htmlElement = htmlElement + element;
        }
    });

    tableElement.innerHTML = htmlElement;

    bbVars.selectControls = document.querySelectorAll('.bb-plan-select-control');
    bbVars.selectControls.forEach(selectControl => {
        selectControl.disabled = true;
        selectControl.addEventListener('change', (event) => bbSelectControlListener(selectControl));
    });

    bbVars.radioControls = document.querySelectorAll('input[type=radio][name="inputplan"]');
    bbVars.radioControls.forEach(radioControl => {
        radioControl.addEventListener('change', (event) => bbRadioControlListener(radioControl));
    });

    bbShowPaymentPlansPage();
    bbShowPreviousPlansBtn();
    bbHideNextPlansBtn();
    bbShowPayBtn();
    bbEnableAllBtns();
}

function bbRadioControlListener(radioControl) {
    if (bbVars.bbPaymentPlans.length > 1) {
        bbVars.selectControls.forEach(selectControl => {
            selectControl.disabled = true;
        });
    }
    if (document.getElementById(radioControl.value + '_select')) {
        document.getElementById(radioControl.value + '_select').disabled = false;
    }
    bbVars.bbPlanSelected = bbGetInfoOfPlan(radioControl.value);
}

function bbSelectControlListener(selectControl) {
    bbVars.bbPlanSelected.NumberOfPayments = selectControl.value;
}

function bbSelectPlanCustomMode(planId, months) {
    if (bbVars.bbConfig['interface'] != 'custom') {
        throw new Error('Esta función solo esta disponible en modo custom');
    }
    if (!!planId && typeof planId === 'string') {
        var planSearched = bbGetInfoOfPlan(planId);
        if (planId != planSearched) {
            bbAfterPayError({
                id: 'BB_PAYMENT_PLAN_SELECTED_ERROR',
                message: 'El plan seleccionado no existe',
                type: 'error',
                error: {
                    trace: {
                        message: 'El plan indicado no existe'
                    }
                }
            }, true);
            bbVars.bbPlanSelected = {
                PlanId: '',
                NumberOfPayments: null,
                NumberOfDeferrals: null
            }
            return;
        }
        if (!!months) {
            if (planSearched.numberOfPayments.includes(months)) {
                bbVars.bbPlanSelected = planSearched;
                bbVars.bbPlanSelected.NumberOfPayments = months;
                return;
            }
        }
        bbAfterPayError({
            id: 'BB_PAYMENT_PLAN_SELECTED_ERROR',
            message: 'El plan seleccionado no tiene disponible el número de meses indicado',
            type: 'error',
            error: {
                trace: {
                    message: 'El plan seleccionado no tiene disponible el número de meses indicado',
                }
            }
        }, true);
        bbVars.bbPlanSelected = {
            PlanId: '',
            NumberOfPayments: null,
            NumberOfDeferrals: null
        }

    } else {
        throw new Error('El Id del plan no es válido');
    }
}

function bbGetInfoOfPlan(planId) {
    var plan = bbVars.bbPaymentPlans.find(p => p['planId'] == planId);
    if (!!plan) {
        if (!!plan['numberOfPayments'] && plan['numberOfPayments'].length >= 1) {
            return {
                PlanId: plan['planId'],
                NumberOfPayments: plan['numberOfPayments'][0],
                NumberOfDeferrals: plan['numberOfDeferrals']
            };
        }
    }
    return {
        PlanId: 'NONEPLAN',
        NumberOfPayments: null,
        NumberOfDeferrals: null
    };
}


export { bbGetPaymentPlans, bbBtnPlanEvent, bbResetPlans, bbSelectPlanCustomMode, bbRadioControlListener, bbSelectControlListener }