import { bbVars, configureEvoPayment } from './configuration';
import { getCardBrands, getCardContents, getButtons, getFields } from './utils';

function bbLoadInterface() {
    if (!bbVars.bbConfig['interface'])
        console.log('Must provide HTML configuration');

    if (bbVars.bbConfig['interface'] == 'custom') {
        initCardFields(bbVars.bbConfig['fields']['card']);
    } else if (bbVars.bbConfig['interface'] == 'modal') {
        initInterface(bbVars.bbConfig['fields']['parent']);
        document.getElementById('bb-payment-modal').classList.add('bb-show');
        document.getElementById('bb-payment-modal-dialog').classList.add('bb-show');
    } else {
        initInterface(bbVars.bbConfig['fields']['parent']);
    }
}

function initInterface(parentId) {
    if (!parentId)
        console.log('Must provide parent container Id');

    if (!document.getElementById(parentId))
        console.log('Element with Id ' + parentId + ' does not exist');

    createHtml(parentId);
    getButtons();
    getCardContents();
    getCardBrands();
    initCardFields(bbVars.bbDefaultFields);
}

function createHtml(parentId) {
    let node = document.createElement('div');
    node.id = "bankbridgeContainer";
    var htmlComponent = bbVars.bbConfig['interface'] == 'modal' ? bbVars.bbHtmlComponentsModal : bbVars.bbHtmlComponentsNoModal;
    htmlComponent = htmlComponent.replace('{{html-general}}', bbVars.htmlComponentGeneral);
    node.innerHTML = htmlComponent;
    document.getElementById(parentId).appendChild(node);

    if (!bbVars.bbConfig['payment']['labelDescription'])
        bbVars.bbConfig['payment']['labelDescription'] = 'Pago en l&iacute;nea';
    document.getElementById('campusModalLabel').innerHTML = bbVars.bbConfig['payment']['labelDescription'] + ': ' + bbVars.bbConfig['payment']['order']['amount'] + ' ' + bbVars.bbConfig['payment']['order']['currency'];
}

function initCardFields(fields) {
    if (typeof fields !== 'object')
        console.log('Field key must be an object');

    Object.keys(fields).forEach(key => {
        if (fields[key].includes('#'))
            fields[key] = fields[key].replace('#', '');
        if (!bbVars.bbRequiredFields.includes(fields[key]))
            console.log('Must provide key: ' + fields[key] + ' in field object');

        fields[key] = '#' + fields[key];
    });

    bbVars.bbCardFields = fields;
    bbInitializePayment();
}

function bbGetPaymentConfiguration() {
    if (!bbVars.bbConfig['payment']['labelDescription'])
        bbVars.bbConfig['payment']['labelDescription'] = 'Pago en línea';

    if (!bbVars.bbConfig['payment']['merchant'])
        console.log('Bad Configuration: must provide Merchant');
    else
        bbVars.bbConfig['merchant'] = bbVars.bbConfig['payment']['merchant'];

    if (!bbVars.bbConfig['payment']['sessionId'])
        console.log('Bad Configuration: must provide SessionId');
    else
        bbVars.bbConfig['sessionId'] = bbVars.bbConfig['payment']['sessionId'];

    bbVars.bbConfig['order'] = {};

    if (!bbVars.bbConfig['payment']['order']['amount'])
        console.log('Bad Configuration: must provide amount');
    else
        bbVars.bbConfig['order']['amount'] = bbVars.bbConfig['payment']['order']['amount'];

    if (!bbVars.bbConfig['payment']['order']['currency'])
        console.log('Bad Configuration: must provide currency');
    else
        bbVars.bbConfig['order']['currency'] = bbVars.bbConfig['payment']['order']['currency'];



}

function bbInitializePayment() {
    getFields();
    bbVars.configureEvoPaymentTimer = setTimeout(() => configureEvoPayment(), 1500);
}

function bbLoadScript() {
    bbGetPaymentConfiguration();

    if (!bbVars.bbConfig['merchant'])
        console.log('Must provide a merchant');

    bbVars.merchant = bbVars.bbConfig['merchant'];

    var myCSS = document.querySelector("link[href*='bankbridge.min.css']");
    if (!myCSS) {
        var urlStyle = bbVars.bbUrlBase + 'css/bankbridge.min.css';
        createStyle(urlStyle)
    }

    var myScript = document.querySelector("script[src*='evopaymentsmexico']");
    if (!myScript) {
        var url = bbVars.bbEvoUrlLib.replace('{{merchant}}', bbVars.merchant);
        bbCreateScript(url, 'evopaymentscript', bbOnloadEvoCallback, null)
    }
};

function bbCreateScript(source, id, onloadCb, attrs) {
    let node = document.createElement('script');
    node.src = source;
    node.type = 'text/javascript';
    node.charset = 'utf-8';
    node.async = true;
    node.setAttribute('id', id);
    if (onloadCb) {
        node.onload = onloadCb();
    }
    if (attrs) {
        attrs.forEach(data => {
            node.setAttribute(data.attribute, data.value)
        });
    }
    injectNode(node);
}

function bbOnloadEvoCallback() {
    bbVars.bbEvoLibLoaded = true;
}

function createStyle(source) {
    let node = document.createElement('link');
    node.href = source;
    node.rel = 'stylesheet';
    injectNode(node);
}

function injectNode(node) {
    let refNode = document.querySelector("script[src*='bankbridge.js']");
    if (!refNode) {
        refNode = document.querySelector("script[src*='bankbridge.min.js']")
    }
    if (!refNode) {
        console.log('The bankbridge.js script does not exist in the page');
        return;
    }
    document.getElementsByTagName('head')[0].insertBefore(node, refNode);
}

function bbRemoveNode() {
    var myScript = document.querySelector("script[src*='evopaymentsmexico']");
    if (myScript) {
        document.head.removeChild(myScript);
    }
}

export { bbLoadInterface, bbRemoveNode, bbLoadScript }