const axios = require('axios').default;
import { of, from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { bbDisplayError } from './utils'
import { bbVars } from './configuration';
import { BBCustomError } from './error';

function bBHTTP_INTERCEPTOR() {
    axios.interceptors.request.use(function(config) {
        config.headers = !!config.headers ? config.headers : {};

        if (!config.url.includes('Request/init')) {
            config.headers['Authorization'] = 'Bearer ' + bbVars.bbToken;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    }, function(error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        return Promise.reject(error);
    });
}

const bBInterceptor = new bBHTTP_INTERCEPTOR();

function bBGET(url, bypassError) {
    var request = axios.get(bbVars.bbUrlBase + url, {
            validateStatus: function(status) {
                return status < 300;
            }
        })
        .then((response) => response.data)
        .catch((error) => bBHandlerError(error));

    return from(request)
        .pipe(map(response => response.data))
        .pipe(catchError(error => { throw error }));
}

function bBPOST(url, body, bypassError) {
    var request = axios.post(bbVars.bbUrlBase + url, body, {
            validateStatus: function(status) {
                return status < 300;
            }
        })
        .then((response) => response.data)
        .catch((error) => bBHandlerError(error));

    return from(request)
        .pipe(map(response => response.data))
        .pipe(catchError(error => { throw error }));
}

function bBPUT(url, body, bypassError) {
    var request = axios.put(bbVars.bbUrlBase + url, body, {
            validateStatus: function(status) {
                return status < 300;
            }
        })
        .then((response) => response.data)
        .catch((error) => bBHandlerError(error));

    return from(request)
        .pipe(map(response => response.data))
        .pipe(catchError(error => { throw error }));
}

function bBHandlerError(error) {
    throw new BBCustomError(error.response.data.message, error.response.data.data, error.response.data.details, error.response.data.errorPriority, error.response.data.httpStatusCode)
}

function bbRequestError(error) {
    var response = {};
    try {
        response = JSON.parse(error.response);
    } catch (error) {
        response = { message: error.response };
    }
    bbDisplayError(response.message);
}

export { bBInterceptor, bBGET, bBPOST, bBPUT, bbRequestError }