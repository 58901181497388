import variables from './vars';
import {
    bbNumberValidation,
    bbExpiryMonthValidation,
    bbExpiryYearValidation,
    bbNameOnCardValidation,
    bbValidateSecurityCode,
    bbDispatchErrorValidation,
    bbSelectErrorValidation,
    bbCardTypeChanged
} from './validations'
import { bBGET, bbRequestError } from './http';
import { bbEnableAllBtns, bbSetBBPhase, bbShowCardPage, bbHidePreloadingPage, bbShowErrorPage } from './utils';

let bbVars = new variables();

function configureEvoPayment() {
    if (!bbVars.bbConfig['sessionId'])
        console.log('Must provide a sessionId');

    if (!bbVars.bbConfig['order'] || !bbVars.bbConfig['order']['amount'] || !bbVars.bbConfig['order']['currency'])
        console.log('Must provide order data');

    bbVars.bbEvoPaymentConfigurations['session'] = bbVars.bbConfig['sessionId'];
    bbVars.bbEvoPaymentConfigurations['fields']['card'] = bbVars.bbCardFields;
    bbVars.bbEvoPaymentConfigurations['order'] = bbVars.bbConfig['order'];
    if (typeof PaymentSession !== 'undefined') {
        PaymentSession.configure(bbVars.bbEvoPaymentConfigurations, bbVars.bbScope);

        clearTimeout(bbVars.configureEvoPaymentTimer);

        PaymentSession.onFocus(['card.nameOnCard',
            'card.number',
            'card.expiryMonth',
            'card.expiryYear',
            'card.securityCode'
        ], (selector, role) => {
            bbVars[role + 'InputSelector'].classList.add('form-focus');
        }, bbVars.bbScope);

        PaymentSession.onBlur(['card.nameOnCard',
            'card.number',
            'card.expiryMonth',
            'card.expiryYear',
            'card.securityCode'
        ], (selector, role) => {
            bbVars[role + 'InputSelector'].classList.remove('form-focus');
        }, bbVars.bbScope);

        PaymentSession.onValidityChange(['card.number'], (selector, result) => bbNumberValidation(selector, result), bbVars.bbScope);

        PaymentSession.onValidityChange(['card.expiryMonth'], (selector, result) => bbExpiryMonthValidation(selector, result), bbVars.bbScope);

        PaymentSession.onValidityChange(['card.expiryYear'], (selector, result) => bbExpiryYearValidation(selector, result), bbVars.bbScope);

        PaymentSession.onValidityChange(['card.securityCode'], (selector, result) => {
            bbVars.sucurityCodeValid = result.isValid;
            bbValidateSecurityCode(selector);
        }, bbVars.bbScope);

        PaymentSession.onEmptinessChange(['card.nameOnCard'], (selector, result) => bbNameOnCardValidation(selector, result), bbVars.bbScope);

        PaymentSession.onEmptinessChange(['card.securityCode'], (selector, result) => {
            bbVars.sucurityCodeEmpty = result.isEmpty;
            bbValidateSecurityCode(selector);
        }, bbVars.bbScope);

        PaymentSession.onCardTypeChange((selector, result) => bbCardTypeChanged(selector, result), bbVars.bbScope);


    } else {
        bbVars.configureEvoPaymentTimer = setTimeout(() => configureEvoPayment(), 1500);
    }
}

function afterInitialized(response) {
    if (bbVars.fromRedirect) {
        bbSetBBPhase(bbVars.bbVars.bbPhases.BB_AUTHENTICATE_PHASE);
        bbAuthenticatedPageLoaded('Authenticated');
    } else {
        canProccedToPayment().subscribe({
            next: (result) => {
                console.log('canProccedToPayment: ', result);
                if (result) {
                    bbSetBBPhase('BB_INIT_PHASE');
                    bbHidePreloadingPage();
                    bbShowCardPage()
                    if (response.status === "ok") {
                        setTimeout(() => bbEnableAllBtns(), 100);
                    } else if (response.status === "system_error") {
                        console.log("initialized system_error", response);
                        if (response.message.includes('Form Session')) {
                            bbRequestError({ response: JSON.stringify({ message: response.message }) });
                        } else {
                            setTimeout(() => bbEnableAllBtns(), 100);
                        }
                    }
                } else {
                    bbHidePreloadingPage();
                    bbRequestError({ response: JSON.stringify({ message: "El servicio de pagos no se encuentra disponible en este momento" }) });
                }
            },
            error: (error) => {
                bbHidePreloadingPage();
                bbShowErrorPage(error.message, error.errorPriority);
            }
        })

    }
}

function canProccedToPayment() {
    return bBGET('api/Request/isEnabledService');
}

export { bbVars, configureEvoPayment, afterInitialized }