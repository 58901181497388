class BBCustomError extends Error {
    httpStatusCode;
    details;
    errorPriority;
    data;
    constructor(message, data, details, errorPriority, httpStatusCode) {
        super(message);
        this.data = data;
        this.details = details;
        this.errorPriority = errorPriority;
        this.httpStatusCode = httpStatusCode;
        this.name = "Bankbridge ERROR";
    }
}
export { BBCustomError };