import { bbVars } from './configuration'
import { bbAfterPayError } from './events'
import { bBPUT } from './http'
import { bbDisableAllBtns, bbShowLoadingPage, bbShowErrorPage, bbSetBBPhase, bbEnableCancelBtn, bbEnablePreviousPlansBtn } from './utils'
import { bbApplyPay, bbPaymentConfirmed } from './pay'

function bbBtnPayEvent(event) {
    bbVars.formSessionUpdated = true;
    bbVars.formSessionUpdateCallBack = () => {
        if (bbVars.bbConfig['interface'] == 'custom') {
            if (bbVars.bbPaymentPlans.length >= 1) {
                if (!bbVars.bbPlanSelected.PlanId) {
                    bbVars.bbPaymentResultsCallback({
                        id: 'BB_PAYMENT_PLAN_SELECTED_ERROR',
                        type: 'error',
                        message: 'No se ha especificado plan de pagos',
                        error: {
                            trace: {
                                message: 'Se debe indicar un plan de pagos'
                            }
                        }
                    });
                    return;
                }
                if (bbVars.bbPlanSelected.PlanId != 'NONEPLAN' && !bbVars.bbPlanSelected.numberOfPayments) {
                    bbVars.bbPaymentResultsCallback({
                        id: 'BB_PAYMENT_PLAN_SELECTED_ERROR',
                        type: 'error',
                        message: 'No se ha especificado el número de meses a pagar',
                        error: {
                            trace: {
                                message: 'Se debe indicar el número de meses a pagar'
                            }
                        }
                    });
                    return;
                }
            } else {
                bbVars.bbPlanSelected = {
                    PlanId: 'NONEPLAN',
                    NumberOfPayments: null,
                    NumberOfDeferrals: null
                };
            }
        }
        bbDisableAllBtns();
        bbShowLoadingPage('Iniciando proceso de pago');
        bbInitAuthentication();
    };
    PaymentSession.updateSessionFromForm('card', 'card-type', bbVars.bbScope);
}

function bbInitAuthentication() {
    if (bbVars.bbCurrentPhase == bbVars.bbPhases.BB_CANCELED_PHASE || bbVars.bbCurrentPhase == bbVars.bbPhases.BB_PAYED_PHASE) {
        throw new Error('bbInitAuthentication: El pago se encuentra cancelado, pagado o existe un problema con la solicitud');
        return;
    }
    bbSetBBPhase('BB_AUTHENTICATE_PHASE');
    bbShowLoadingPage('Autenticando proceso de pago');
    bBPUT('api/Request/authenticate', {}).subscribe({
        next: (data) => {
            setTimeout(() => displayAuthenticationWindow(data), 100);
        },
        error: (error) => {
            bbShowErrorPage(error.message, error.errorPriority);
            bbAfterPayError({
                id: 'BB_INIT_AUTHENTICATION_ERROR',
                message: error.message,
                type: 'error',
                error: {
                    trace: null //error
                }
            })
        }
    });
}



function displayAuthenticationWindow(data) {
    if (data.payment) {
        bbPaymentConfirmed(data.payment);
    } else {

        if (data.redirectHtml) {
            var htmlString = decodeURIComponent(escape(atob(data.redirectHtml)));
            if (!!bbVars.bbConfig['athenticationMode'] && bbVars.bbConfig['athenticationMode'] == 'redirect') {
                window.document.write(htmlString);
                window.document.close();
                window.focus();
            } else {
                if (bbVars.windowObjectReference == null) {
                    var htmlButton = '<style>body{overflow-x:hidden;}iframe{width:100%;height:100%;overflow-x:hidden;}</style><div style="width:100%;text-align:center;padding:10px;background-color:#fff;"><button id="bbcloseAuthbtn" style="padding: 4px 12px;border-radius:12px;border:2px;color:#fff;font-size:14px;background-color:#0d6efd;border-color:#0d6efd;cursor:pointer;">X Cerrar</button></div>'
                    var htmlScript = 'var BBIframe=null,win=null,counterload=0,onAuthenticated=()=>{null==BBIframe&&((BBIframe=document.getElementById("redirectTo3ds1Frame"))||(BBIframe=document.querySelector("iframe"))),BBIframe&&(window.parent&&window.parent.BankBridge.onAuthenticated?setTimeout((()=>window.parent.BankBridge.onAuthenticated("BB_AUTHENTICATION_COMPLETE")),2500):setTimeout((()=>window.parent.postMessage("BB_AUTHENTICATION_COMPLETE","*")),2500))},onCloseWindow=()=>{window.parent&&window.parent.BankBridge.onAuthenticated?(window.parent.BankBridge.onAuthenticated("BB_AUTHENTICATION_CLOSED")):(window.parent.postMessage("BB_AUTHENTICATION_CLOSED","*"))},initMessageHandler=()=>{window.addEventListener("message",(e=>{"BB_AUTHENTICATION_LOADED"==e.data&&onAuthenticated(),"Close"==e.data&&onCloseWindow()}),!1)};window.addEventListener("load",(()=>initMessageHandler())),window.addEventListener("unload",(()=>onCloseWindow()));document.getElementById("bbcloseAuthbtn").addEventListener("click",()=>(onAuthenticated()))';

                    htmlString = htmlButton + htmlString + '<script id="authenticate-payer-script">' + htmlScript + '</script>';

                    var node = document.createElement('iframe');

                    node.setAttribute('id', 'bb-payment-iframe-pay')
                    node.setAttribute('class', 'bb-payment-iframe-pay');

                    var body = window.parent.document.getElementsByTagName('body');
                    if (body[0]) {
                        body[0].appendChild(node);
                    }
                    bbVars.windowObjectReference = document.getElementById('bb-payment-iframe-pay');
                    bbVars.windowObjectReference.contentWindow.document.write(htmlString);
                    bbVars.windowObjectReference.contentWindow.document.close();
                    bbVars.windowObjectReference.contentWindow.focus();
                } else {
                    bbVars.windowObjectReference.focus();
                };

                window.addEventListener('message', (e) => {
                    if (e.data == bbVars.bbAuthenticationComplete)
                        bbAuthenticatedPageLoaded(e.data);
                    if (e.data == 'BB_AUTHENTICATION_CLOSED')
                        checkWindowIsClose(e.data);
                }, false);
            }
        } else {
            bbShowErrorPage('Error al cargar ventana de autenticación');
            bbAfterPayError({
                id: 'BB_AUTHENTICATION_ERROR',
                message: 'Error al cargar ventana de autenticación',
                type: 'error',
                error: {
                    trace: {
                        message: 'La solicitud de autenticación no devolvió HTML'
                    }
                }
            });
        }
    }
}

function closeWindowAuth() {
    if (bbVars.windowObjectReference != null) {
        document.getElementsByTagName('body')[0].removeChild(bbVars.windowObjectReference);
        bbVars.windowObjectReference = null;
    }
}

function checkWindowIsClose(message) {
    if (message == bbVars.bbAuthenticationClose && !bbVars.windowAuthenticated) {
        if (bbVars.windowCloseCheckedCounter >= 3) {
            if (bbVars.windowObjectReference != null) {
                closeWindowAuth();
                AuthenticationFailed();
            }
        } else {
            bbVars.windowCloseCheckedCounter++;
            setTimeout(() => checkWindowIsClose(message), 200);
        }
    } else {
        setTimeout(() => {
            if (bbVars.bbCurrentPhase == bbVars.bbPhases.BB_AUTHENTICATE_PHASE) {
                bbAuthenticatedPageLoaded('Authenticated');
            }
        }, 2000);
    }
}

function AuthenticationFailed() {
    bbShowErrorPage('El proceso de autenticación no pudo completarse. El ususario cerro la ventana de autenticación.', 1);
    bbEnableCancelBtn();
    bbEnablePreviousPlansBtn();

    bbAfterPayError({
        id: 'BB_AUTHENTICATION_CANCELED',
        message: 'Error ocurrido en el proceso de autenticación',
        type: 'error',
        error: {
            trace: {
                message: 'El usuario cerro la ventana de autenticación'
            }
        }
    });
}

function bbAuthenticatedPageLoaded(message) {
    if (bbVars.bbConfig['interface'] == 'modal') {
        var modal = document.querySelector('#bankbridgeContainer');
        if (modal == null || typeof modal === 'undefined') {
            closeWindowAuth();
            return;
        }
    }
    if (message == bbVars.bbAuthenticationComplete) {
        bbVars.windowAuthenticated = true;
        closeWindowAuth();
        afterAuthenticated()
    }
}

function afterAuthenticated() {
    bbVars.windowObjectReference = null;
    bbApplyPay()
}

export { bbInitAuthentication, displayAuthenticationWindow, checkWindowIsClose, AuthenticationFailed, bbAuthenticatedPageLoaded, afterAuthenticated, bbBtnPayEvent, closeWindowAuth }