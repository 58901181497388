import { bbVars } from './configuration'
import { bbAfterPayError, bbCloseModal } from './events'
import { bbShowLoadingPage, bbSetBBPhase, bbShowErrorPage, bbEnablePreviousPlansBtn, bbShowPreviousPlansBtn } from './utils'
import { bBPUT } from './http'

var seconds = 1;

function bbApplyPay() {
    if (bbVars.windowObjectReference != null)
        return;

    if (bbVars.bbCurrentPhase != bbVars.bbPhases.BB_AUTHENTICATE_PHASE) {
        return;
    }

    var body = {
        paymentPlan: (!!bbVars.bbPlanSelected && bbVars.bbPlanSelected.PlanId != 'NONEPLAN' && bbVars.bbPlanSelected.PlanId != '') ? bbVars.bbPlanSelected : null
    };
    bbSetBBPhase('BB_CONFIRM_PHASE');
    bbShowLoadingPage('Autenticando proceso de pago');
    bBPUT('api/Request/confirm', body).subscribe({
        next: (data) => {
            bbPaymentConfirmed(data);
        },
        error: (error) => {
            bbShowErrorPage(error.message, error.errorPriority);
            bbShowPreviousPlansBtn();
            bbEnablePreviousPlansBtn();
            bbAfterPayError({
                id: 'BB_CONFIRMATION_ERROR',
                type: 'error',
                message: 'Error al confirmar el pago',
                error: {
                    trace: null //error
                }
            });
        }
    });
}

function bbPaymentConfirmed(data) {
    if (data.order && (data.order.authenticationStatus == 'AUTHENTICATION_SUCCESSFUL' || data.order.authenticationStatus == 'AUTHENTICATION_NOT_IN_EFFECT' || data.order.authenticationStatus == 'AUTHENTICATION_ATTEMPTED')) {
        bbShowLoadingPage('Autentición exitosa');
        bbAfterPayAplied(data);
    } else {
        bbShowErrorPage('Autenticación de pago no exitosa');
        bbAfterPayError({
            id: 'BB_AUTHENTICATION_UNSUCCESSFUL',
            message: 'Autenticación de pago no exitosa',
            type: 'error',
            error: {
                trace: null //data
            }
        });
    }
}

function bbAfterPayAplied(data) {
    if (data.response && data.response.gatewayCode == 'APPROVED') {
        bbSetBBPhase('BB_PAYED_PHASE');
        bbShowLoadingPage('Pago aplicado');
        var resultData = {
            id: 'BB_PAYMENT_APPROVED',
            message: 'Pago aprobado',
            type: 'success',
            data: {
                trace: null //data.response
            }
        };
        bbVars.bbPaymentResultsCallback(resultData)
        if (bbVars.bbCompleteResolver) {
            bbVars.bbCompleteResolver(resultData);
        }


        if (!!bbVars.bbConfig['payment']['redirectUrl']) {
            if (!bbVars.bbConfig['payment']['redirectUrl'].includes("payment/authenticated")) {
                var interval = setInterval(backcounter, 1000);
            }
        }

        bbCloseModal();
    } else {
        bbShowLoadingPage('El pago no se pudo aplicar');
        bbAfterPayError({
            id: 'BB_PAYMENT_APPROVED_ERROR',
            message: 'Pago no aprobado',
            type: 'error',
            error: {
                trace: null //data.response
            }
        });
    }
}

function backcounter() {
    seconds = seconds - 1;
    if (seconds <= 0) {
        document.getElementById('redirectLink').click();
    }
}

export { bbApplyPay, bbPaymentConfirmed, bbAfterPayAplied }