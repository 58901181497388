import { bbVars } from './configuration'
import { bbSelectErrorValidation } from './validations'
import { bbShowErrorPage, bbShowLoadingPage, bbGoToCardForm, bbDisableAllBtns, bbEnableAllBtns, bbEnableCancelBtn, bbClearEventListeners, bbSetBBPhase } from './utils'
import { bbRemoveNode } from './interface'
import { bbBtnPlanEvent } from './payment-plans'
import { bBPOST } from './http'
import { bbBtnPayEvent, closeWindowAuth } from './authentication'


function bbDispatchNextEvent(event) {
    bbDisableAllBtns();
    if (bbVars.bbPayAttemps < 2) {
        PaymentSession.validate('card', (result) => {
            if (result.card) {
                if (result.card.isValid) {
                    switch (event) {
                        case 'pay':
                            bbBtnPayEvent('pay');
                            break;
                        case 'payment-plans':
                            bbBtnPlanEvent();
                            break;
                        case 'other':
                            break;
                        default:
                            break;
                    }
                } else {
                    var filedkeys = Object.keys(result.card);
                    for (var i = 0; i < filedkeys.length; i++) {
                        if (filedkeys[i] != 'isValid') {
                            if (!!result.card[filedkeys[i]].hasError) {
                                var fatalSessionErrors = ['SESSION_AUTHENTICATION_LIMIT_EXCEEDED', 'NOT_AUTHORIZED', 'SYSTEM_ERROR'];
                                if (fatalSessionErrors.includes(result.card[filedkeys[i]].errorReason)) {
                                    bbShowErrorPage('La sesión de pago ha expirado');
                                    bbAfterPayError({
                                        id: 'BB_PAYMENT_VALIDATION_ERROR',
                                        type: 'error',
                                        message: 'No es posible validar los datos de la tarjeta',
                                        error: {
                                            trace: {
                                                message: 'La sesión de EVO ha expirado'
                                            }
                                        }
                                    });
                                    break;
                                }
                                var nonFatalSessionError = ['AWAITING_SERVER_RESPONSE', 'TIMEOUT'];
                                if (nonFatalSessionError.includes(fatalSessionErrors.includes(result.card[filedkeys[i]].errorReason))) {
                                    bbVars.bbPayAttemps++;
                                    setTimeout(() => () => bbDispatchNextEvent(event), 2500);
                                    break;
                                }
                            }
                            bbSelectErrorValidation(filedkeys[i], result.card[filedkeys[i]]);
                        }
                    };
                    bbEnableAllBtns();
                }
            }
        }, bbVars.bbScope);
    } else {
        bbShowErrorPage('No es posible validar los datos de la tarjeta: El servicio de EVO no esta disponble');
        bbAfterPayError({
            id: 'BB_PAYMENT_VALIDATION_ERROR',
            message: 'No es posible validar los datos de la tarjeta',
            type: 'error',
            error: {
                trace: {
                    message: 'El servicio de EVO no esta disponble'
                }
            }
        });
        bbEnableCancelBtn();
    }
}

function bbDispatchPreviousEvent(event) {
    switch (event) {
        case 'payment-plans':
            bbSetBBPhase('BB_INIT_PHASE');
            bbGoToCardForm();
            break;
        case 'other':
            break;
        default:
            break;
    }
}


function bbAfterPayError(data, skipResolve) {
    bbVars.bbPaymentResultsCallback(data);
    if (!skipResolve) {
        if (bbVars.bbErrorResolver) {
            setTimeout(() => bbVars.bbErrorResolver(data), 100);
        }
    }
}

function bbPaymentComplete() {
    return new Promise(function(resolve, reject) {
        bbVars.bbCompleteReject = reject;
        bbVars.bbCompleteResolver = resolve;
    });
}


function bbPaymentError() {
    return new Promise(function(resolve, reject) {
        bbVars.bbErrorReject = reject;
        bbVars.bbErrorResolver = resolve;
    });
}

function bbDispatchCancel() {
    bbCancelRequest();
    var cancelData = {
        id: 'BB_PAYMENT_CANCELED',
        message: 'Pago cancelado por el usuario',
        type: 'error',
        error: {
            trace: {
                message: 'Operation canceled by user'
            }
        }
    };
    bbAfterPayError(cancelData);
    if (!!bbVars.bbConfig['payment']['redirectUrl']) {
        if (!bbVars.bbConfig['payment']['redirectUrl'].includes("payment/authenticated")) {
            setTimeout(() => window.location = bbVars.bbConfig['payment']['redirectUrl'], 100);
        }
    }
    if (bbVars.windowObjectReference) {
        //var r = window.open();
        //bbVars.windowObjectReference.close();
        document.getElementsByTagName('body')[0].removeChild(bbVars.windowObjectReference);
        bbVars.windowObjectReference = null;
    }
    bbClearEventListeners();
    bbCloseModal();
};

function bbCancelRequest() {
    bbShowLoadingPage('Cancelando pago');
    bBPOST('api/Request/cancel', {}, true).subscribe({
        next: (data) => {
            bbShowErrorPage('Pago cancelado', 1);
        },
        error: () => {}
    });
}

function bbCloseModal() {
    var bankbridgeContainer = document.getElementById(bbVars.bbConfig['fields']['parent']);
    if (bankbridgeContainer) {
        var child = bankbridgeContainer.lastElementChild;
        while (child) {
            bankbridgeContainer.removeChild(child);
            child = bankbridgeContainer.lastElementChild;
        }
        closeWindowAuth();
        closeIframe();
        bbRemoveNode();
    }
}


function closeIframe() {
    if (bbVars.isIframe) {
        var parent = window.parent;
        var iframe = window.parent.document.getElementById('bb-payment-iframe-pay');
        if (iframe) {
            window.parent.removeChild(iframe);
            parent.document.removeChild(iframe);
        }
    }
}


export { bbDispatchNextEvent, bbDispatchPreviousEvent, bbAfterPayError, bbPaymentComplete, bbPaymentError, bbDispatchCancel, bbCancelRequest, bbCloseModal }