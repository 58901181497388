import { bbVars } from './configuration'
import { bbGetPaymentPlans, bbResetPlans } from './payment-plans'
import { bbRemoveOpacityCard, bbAddOpacityCard } from './utils'

function bbNumberValidation(selector, result) {
    var labelStr = '';
    if (!result.isValid) {
        labelStr = 'El n&uacute;mero de tarjeta no es v&aacute;lido';
        if (result.errorReason == 'EMPTY')
            labelStr = 'Este campo es obligatorio';
    }
    bbDispatchErrorValidation('number', labelStr);
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    /*if (result.isValid) {
        bbVars.numberInputSelector.classList.remove('is-invalid');
    } else {
        bbVars.numberInputSelector.classList.add('is-invalid');
    }*/
    bbVars.errornumberInputSelector.innerHTML = labelStr;
    //PaymentSession.setMessage('card.number', labelStr, bbVars.bbScope);
};

function bbExpiryMonthValidation(selector, result) {
    var labelStr = '';
    if (!result.isValid) {
        labelStr = 'El mes de vencimiento no es v&aacute;lido';
        if (result.errorReason == 'EMPTY')
            labelStr = 'Este campo es obligatorio';
    }
    bbDispatchErrorValidation('expiryMonth', labelStr);
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    /*if (result.isValid) {
        bbVars.expiryMonthInputSelector.classList.remove('is-invalid');
    } else {
        bbVars.expiryMonthInputSelector.classList.add('is-invalid');
    }*/
    bbVars.errorexpiryMonthInputSelector.innerHTML = labelStr;
}

function bbExpiryYearValidation(selector, result) {
    var labelStr = '';
    if (!result.isValid) {
        labelStr = 'El a&ntilde;o de vencimiento no es v&aacute;lido';
        if (result.errorReason == 'EMPTY')
            labelStr = 'Este campo es obligatorio';
    }
    bbDispatchErrorValidation('expiryYear', labelStr);
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    /*if (result.isValid) {
        bbVars.expiryYearInputSelector.classList.remove('is-invalid');
    } else {
        bbVars.expiryYearInputSelector.classList.add('is-invalid');
    }*/
    bbVars.errorexpiryYearInputSelector.innerHTML = labelStr;
}

function bbNameOnCardValidation(selector, result) {
    var labelStr = !result.isEmpty ? '' : 'Este campo es obligatorio';
    bbDispatchErrorValidation('nameOnCard', labelStr);
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    /*if (!result.isEmpty) {
        bbVars.nameOnCardInputSelector.classList.remove('is-invalid');
    } else {
        bbVars.nameOnCardInputSelector.classList.add('is-invalid');
    }*/
    bbVars.errornameOnCardInputSelector.innerHTML = labelStr;
}

function bbValidateSecurityCode(selector) {
    var labelStr = '';
    if (bbVars.sucurityCodeEmpty) {
        labelStr = 'Este campo es obligatorio';
    }
    if (!bbVars.sucurityCodeValid) {
        labelStr = 'El c&oacute;digo de seguridad no es v&aacute;lido';
    }
    bbDispatchErrorValidation('securityCode', labelStr);
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    /*if (!!labelStr) {
        bbVars.securityCodeInputSelector.classList.add('is-invalid');
    } else {
        bbVars.securityCodeInputSelector.classList.remove('is-invalid');
    }*/
    bbVars.errorsecurityCodeInputSelector.innerHTML = labelStr;
}

function bbDispatchErrorValidation(field, error) {
    bbVars.bbFieldsErrors.errors[field] = {
        hasError: !!error,
        error: error
    }
    var hasError = false;
    Object.keys(bbVars.bbFieldsErrors.errors).forEach(error => {
        if (bbVars.bbFieldsErrors.errors[error].hasError) {
            hasError = bbVars.bbFieldsErrors.errors[error].hasError;
        }
    });
    bbVars.bbFieldsErrors.hasError = hasError || !!error;
    bbVars.bbValidationCallback(bbVars.bbFieldsErrors);
}

function bbSelectErrorValidation(field, result) {
    switch (field) {
        case 'number':
            bbNumberValidation(result.selector, result);
            break;
        case 'expiryMonth':
            bbExpiryMonthValidation(result.selector, result);
            break;
        case 'expiryYear':
            bbExpiryYearValidation(result.selector, result);
            break;
        case 'securityCode':
            bbVars.sucurityCodeValid = result.isValid;
            bbValidateSecurityCode(result.selector);
            break;
        case 'nameOnCard':
            bbNameOnCardValidation(result.selector, result);
            break;
        default:
            break;
    }
}

function bbCardTypeChanged(selector, result) {
    if (result.status === 'SUPPORTED') {
        bbGetPaymentPlans(selector, result);

        if (bbVars.bbConfig['interface'] == 'custom')
            return;

        switch (result.scheme) {
            case 'AMEX':
                bbAddOpacityCard('visa');
                bbAddOpacityCard('mastercard');
                bbAddOpacityCard('carnet');
                break;
            case 'VISA':
                bbAddOpacityCard('amex');
                bbAddOpacityCard('mastercard');
                bbAddOpacityCard('carnet');
                break;
            case 'MASTERCARD':
                bbAddOpacityCard('visa');
                bbAddOpacityCard('amex');
                bbAddOpacityCard('carnet');
                break;
            default:
                bbRemoveOpacityCard('visa');
                bbRemoveOpacityCard('amex');
                bbRemoveOpacityCard('mastercard');
                bbRemoveOpacityCard('carnet');
                break;
        }
    } else if (result.status === 'INSUFFICIENT_LENGTH') {
        bbRemoveOpacityCard('visa');
        bbRemoveOpacityCard('amex');
        bbRemoveOpacityCard('mastercard');
        bbRemoveOpacityCard('carnet');
        bbResetPlans();
    } else if (result.status === 'NOT_SUPPORTED') {
        bbRemoveOpacityCard('visa');
        bbRemoveOpacityCard('amex');
        bbRemoveOpacityCard('mastercard');
        bbRemoveOpacityCard('carnet');
        bbResetPlans();
    }
}

export { bbNumberValidation, bbExpiryMonthValidation, bbExpiryYearValidation, bbNameOnCardValidation, bbValidateSecurityCode, bbDispatchErrorValidation, bbSelectErrorValidation, bbCardTypeChanged }