import { bbVars } from './configuration';
import { bbSelectControlListener, bbRadioControlListener } from './payment-plans';

function bbGetBBScope(scope) {
    var rnd = Math.floor(Math.random() * (100 - 1)) + 1;
    bbVars.bbScope = 'bb' + scope + String(rnd);
}

function bbGetUrlBase() {

    //bbVars.bbUrlBase = 'https://localhost:5001/';
    //return;
    var bankbridgeScript = document.querySelector("script[src*='bankbridge.js']");
    var url = !!bankbridgeScript ? bankbridgeScript.src : '';
    if (!!url) {
        const baseuri = url.split('js');
        bbVars.bbUrlBase = baseuri[0];
    } else {
        bankbridgeScript = document.querySelector("script[src*='bankbridge.min.js']");
        url = !!bankbridgeScript ? bankbridgeScript.src : '';
        if (!!url) {
            const baseuri = url.split('js');
            bbVars.bbUrlBase = baseuri[0];
        } else {
            console.log('The bankbridge.js script does not exist in the page');
        }
    }
}

function bbEnablePayBtn() {
    bbVars.btnPaySelector.disabled = false;
}

function bbDisablePayBtn() {
    bbVars.btnPaySelector.disabled = true;
}

function bbEnableCancelBtn() {
    bbVars.btnCancelSelector.disabled = false;
}

function bbDisableCancelBtn() {
    bbVars.btnCancelSelector.disabled = true;
}

function bbEnableNextPlansBtn() {
    bbVars.btnNextSelector.disabled = false;
}

function bbDisableNextPlansBtn() {
    bbVars.btnNextSelector.disabled = true;
}

function bbEnablePreviousPlansBtn() {
    bbVars.btnPrevSelector.disabled = false;
}

function bbDisablePrevoiusPlansBtn() {
    bbVars.btnPrevSelector.disabled = true;
}

function bbShowPayBtn() {
    bbVars.btnPaySelector.classList.remove('bb-hide');
}

function bbHidePayBtn() {
    bbVars.btnPaySelector.classList.add('bb-hide');
}

function bbShowCancelBtn() {
    bbVars.btnCancelSelector.classList.remove('bb-hide');
}

function bbHideCancelBtn() {
    bbVars.btnCancelSelector.classList.add('bb-hide');
}

function bbShowNextPlansBtn() {
    bbVars.btnNextSelector.classList.remove('bb-hide');
}

function bbHideNextPlansBtn() {
    bbVars.btnNextSelector.classList.add('bb-hide');
}

function bbShowPreviousPlansBtn() {
    bbVars.btnPrevSelector.classList.remove('bb-hide');
}

function bbHidePreviousPlansBtn() {
    bbVars.btnPrevSelector.classList.add('bb-hide');
}

function bbDisableAllBtns() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbDisablePayBtn();
    bbDisableCancelBtn();
    bbDisableNextPlansBtn();
    bbDisablePrevoiusPlansBtn();
}

function bbEnableAllBtns() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbEnablePayBtn();
    bbEnableCancelBtn();
    bbEnableNextPlansBtn();
    bbEnablePreviousPlansBtn();
}

function bbShowSpinnerBtn() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbVars.btnPaySpinnerSelector.classList.remove('bb-hide');
}

function bbHideSpinnerBtn() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbVars.btnPaySpinnerSelector.classList.add('bb-hide');
}

function bbShowLoadingPage(message = '') {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbHideCardPage();
    bbHideErrorPage();
    bbHidePaymentPlansPage();
    bbVars.bbLoadingContentSelector.classList.remove('bb-hide');
    bbVars.bbLoadingContentTextSelector.innerHTML = message;
}

function bbHideLoadingPage() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbVars.bbLoadingContentSelector.classList.add('bb-hide');
    bbVars.bbLoadingContentTextSelector.innerHTML = '';
}

function bbShowPreloadingPage(message = '') {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbHideCardPage();
    bbHideErrorPage();
    bbHidePaymentPlansPage();
    bbHideLoadingPage();
    bbVars.bbPreloadContentSelector.classList.remove('bb-hide');
}

function bbHidePreloadingPage() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbVars.bbPreloadContentSelector.classList.add('bb-hide');
}

function bbShowCardPage() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbHideErrorPage();
    bbHideLoadingPage();
    bbHidePaymentPlansPage();
    bbVars.bbCardContentSelector.classList.remove('bb-hide');
}

function bbHideCardPage() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbVars.bbCardContentSelector.classList.add('bb-hide');
}

function bbShowErrorPage(message = '', priority = 0) {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbHideCardPage();
    bbHideLoadingPage();
    bbHidePaymentPlansPage();
    bbDisableAllBtns();
    bbEnableCancelBtn();
    bbShowPreviousPlansBtn();
    bbEnablePreviousPlansBtn();
    bbVars.bbErrorContentSelector.classList.remove('bb-hide');
    if (priority == 0) {
        bbVars.bbErrorImportantContentContainerSelector.classList.add('bb-hide');
        bbVars.bbErrorContentTextSelector.innerHTML = message;
        bbVars.bbCheckInfoMessageSelector.classList.remove('bb-hide');
    } else {
        bbVars.bbErrorImportantContentContainerSelector.classList.remove('bb-hide');
        bbVars.bbErrorImportantContentTextSelector.innerHTML = message;
        bbVars.bbCheckInfoMessageSelector.classList.add('bb-hide');
    }

    if (!!document.getElementById('text-request-id')) {
        document.getElementById('text-request-id').innerHTML = bbVars.bbConfig['payment']['bBId'];
        document.getElementById('text-session-id').innerHTML = bbVars.bbConfig['sessionId'];
        document.getElementById('text-merchan-id').innerHTML = bbVars.bbConfig['merchant'];
    }
}

function bbHideErrorPage() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbVars.bbErrorContentSelector.classList.add('bb-hide');
    bbVars.bbErrorImportantContentContainerSelector.classList.add('bb-hide');
    bbVars.bbErrorContentTextSelector.innerHTML = '';
    bbVars.bbErrorImportantContentTextSelector.innerHTML = '';
}

function bbShowPaymentPlansPage(message = '') {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbHideCardPage();
    bbHideLoadingPage();
    bbHideErrorPage();
    bbVars.bbPaymentPlanContentSelector.classList.remove('bb-hide');
}

function bbHidePaymentPlansPage() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbVars.bbPaymentPlanContentSelector.classList.add('bb-hide');
}

function bbDisplayError(error) {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbShowErrorPage(error);
}

function bbAddOpacityCard(cardBrand) {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbVars[cardBrand + 'CardSelector'].classList.add('bb-card-opacity');
}

function bbRemoveOpacityCard(cardBrand) {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;
    bbVars[cardBrand + 'CardSelector'].classList.remove('bb-card-opacity');
}

function bbGoToCardForm() {
    if (bbVars.bbConfig['interface'] == 'custom')
        return;

    bbClearEventListeners();
    bbShowCardPage();
    bbDisablePrevoiusPlansBtn();
    bbHidePreviousPlansBtn();

    if (bbVars.bbPaymentPlans.length >= 1) {
        bbShowNextPlansBtn();
        bbEnableNextPlansBtn();
        bbDisablePayBtn();
        bbHidePayBtn();
    } else {
        bbShowPayBtn();
        bbEnablePayBtn();
    }
}

function bbSetBBPhase(phase) {
    if (bbVars.bbPhases[phase]) {
        bbVars.bbCurrentPhase = bbVars.bbPhases[phase];
    }
}

function getButtons() {
    bbVars.btnPaySelector = document.querySelector('.btn-pay');
    bbVars.btnCancelSelector = document.querySelector('.btn-cancel');
    bbVars.btnNextSelector = document.querySelector('.btn-next-plans');
    bbVars.btnPrevSelector = document.querySelector('.btn-previous-plans');
    bbVars.btnPaySpinnerSelector = document.querySelector('.btn-pay-spinner');
}

function getCardContents() {
    bbVars.bbLoadingContentSelector = document.querySelector('.bb-loading-content');
    bbVars.bbLoadingContentTextSelector = document.querySelector('.bb-loading-content-text');
    bbVars.bbCardContentSelector = document.querySelector('.bb-card-content');
    bbVars.bbErrorContentSelector = document.querySelector('.bb-error-content');
    bbVars.bbErrorContentTextSelector = document.querySelector('.bb-error-content-text');
    bbVars.bbErrorImportantContentTextSelector = document.querySelector('.bb-error-content-important-text');
    bbVars.bbErrorImportantContentContainerSelector = document.querySelector('.bb-error-content-important-container');
    bbVars.bbCheckInfoMessageSelector = document.querySelector('.bb-check-info-message');
    bbVars.bbPaymentPlanContentSelector = document.querySelector('.bb-payment-plans-content');
    bbVars.bbPreloadContentSelector = document.querySelector('.bb-card-preload');
}

function getCardBrands() {
    bbVars.visaCardSelector = document.querySelector('.bb-visaCardLogo');
    bbVars.mastercardCardSelector = document.querySelector('.bb-mastercardCardLogo');
    bbVars.amexCardSelector = document.querySelector('.bb-amexCardLogo');
    bbVars.carnetCardSelector = document.querySelector('.bb-carnetCardLogo');
}

function getFields() {
    Object.keys(bbVars.bbCardFields).forEach((ele, index) => {
        bbVars[ele + 'InputSelector'] = document.getElementById(bbVars.bbCardFields[ele].replace('#', ''));
        bbVars['error' + ele + 'InputSelector'] = document.getElementById(bbVars.bbCardFields[ele].replace('#', 'error-'));
    });
}

function bbClearEventListeners() {
    if (bbVars.radioControls) {
        bbVars.radioControls.forEach(ele => {
            ele.removeEventListener('change', () => bbRadioControlListener(ele));
        });
    }
    if (bbVars.selectControls) {
        bbVars.selectControls.forEach(ele => {
            ele.removeEventListener('change', () => bbSelectControlListener(ele));
        });
    }
}

function isIframe() {
    var parent = window.parent;
    if (!!parent) {
        var iframe = window.parent.document.getElementById('bb-payment-iframe-pay');
        bbVars.isIframe = !!iframe;
    }
}

function fromRedirect() {
    bbVars.isIframe = true;
}

export {
    bbGetBBScope,
    bbGetUrlBase,
    bbEnablePayBtn,
    bbDisablePayBtn,
    bbEnableCancelBtn,
    bbDisableCancelBtn,
    bbEnableNextPlansBtn,
    bbDisableNextPlansBtn,
    bbEnablePreviousPlansBtn,
    bbDisablePrevoiusPlansBtn,
    bbShowPayBtn,
    bbHidePayBtn,
    bbShowCancelBtn,
    bbHideCancelBtn,
    bbShowNextPlansBtn,
    bbHideNextPlansBtn,
    bbShowPreviousPlansBtn,
    bbHidePreviousPlansBtn,
    bbDisableAllBtns,
    bbEnableAllBtns,
    bbShowSpinnerBtn,
    bbHideSpinnerBtn,
    bbShowLoadingPage,
    bbShowCardPage,
    bbHideCardPage,
    bbShowErrorPage,
    bbHideErrorPage,
    bbShowPaymentPlansPage,
    bbHidePaymentPlansPage,
    bbShowPreloadingPage,
    bbHidePreloadingPage,
    bbDisplayError,
    bbAddOpacityCard,
    bbRemoveOpacityCard,
    bbGoToCardForm,
    bbSetBBPhase,
    getButtons,
    getCardContents,
    getCardBrands,
    getFields,
    bbClearEventListeners,
    isIframe,
    fromRedirect
}